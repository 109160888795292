<template>
  <button class="round-button" type="button">
    <Icon v-if="icon" :class="iClass" aria-hidden="true" />
    <slot />
  </button>
</template>

<script setup>
import { defineAsyncComponent, defineProps } from 'vue'

const props = defineProps(['icon', 'iClass'])

const Icon = defineAsyncComponent(() => import(`@/icons/${props.icon}.isvg`))
</script>

<style>
button.round-button {
  @apply rounded-full bg-white;
}
.round-button.active {
  @apply bg-neutral-100;
}
.round-button:not(:disabled):hover {
  @apply bg-neutral-200;
}
.round-button:disabled {
  @apply opacity-25;
}
.round-button.tr {
  @apply bg-transparent;
}
</style>
