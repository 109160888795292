<template>
  <div 
      v-if="show" 
      :style="{transform: transform}" 
      class="fixed left-0 top-0 p-2 z-10 popup">
      <video v-if="asset.previews?.video_thumbnail"
        class="h-60 max-w-none"
        :src="asset.previews.video_thumbnail.url"
        loop autoplay muted></video>
      <img 
        v-else
        :src="asset.previews.thumbnail.url"
        class="h-60 max-w-none"
        alt="">
      <div class="flex justify-between gap-2 items-end mt-2">
        <div class="">
          {{ asset.title }}
        </div>
        <div class="">
          {{ asset.id }}
        </div>
      </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
  setup() {
    const show = ref(false)
    const transform = ref('')
    const asset = ref({})

    return {
      show,
      transform,
      asset
    }
  },
  mounted() {
    this.$mitt.on('showPreview', this.enter)
    this.$mitt.on('hidePreview', this.leave)
    this.$mitt.on('movePreview', this.move)
  },
  methods: {
    enter(asset) {
      this.asset = asset
      this.show = true
    },
    leave() {
      this.show = false
    },
    move(e) {
      if (!this.show) this.show = true
      const x = e.pageX < window.innerWidth * 0.5 ? `${e.pageX + 20}px` : `calc(${e.pageX - 20}px - 100%)`
      const y = e.pageY < window.innerHeight * 0.5 ? `${e.pageY + 20}px` : `calc(${e.pageY - 20}px - 100%)`
      this.transform = `translate3d(${x}, ${y}, 0)`
    }
  },
  beforeUnmount() {
    this.$mitt.off('showPreview', this.enter)
    this.$mitt.off('hidePreview', this.leave)
    this.$mitt.off('movePreview', this.move)
  }
}
</script>

<style>
  .asset-preview img {
    @apply block max-h-none max-w-none bg-neutral-200;
    min-width: 5rem;
    min-height: 5rem;
  }
</style>