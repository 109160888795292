<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="sm:flex sm:items-start">
                <div class="bg-gray-100 mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full sm:mx-0">
                  <WarningIcon class="w-5 mb-1 text-black" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 class="text-xs font-medium my-3">Automatic sign out due to inactivity</h3>
                  <div class="text-xs mt-1">
                    Your session will expire soon due to inactivity.
                    <br/> 
                    Press continue to stay signed in.
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:justify-end">
                <button class="btn btn--outline" @click="logout">Sign out</button>
                <button class="btn btn--black ml-4" @click="refresh">Continue</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import WarningIcon from '@/icons/warning.isvg'

import { ref } from 'vue'
import { getJWTExpire, getTokens } from '@/helpers'

export default {
  name: 'InactivityPopup',
  components: {
    Dialog, DialogPanel, TransitionChild, TransitionRoot, 
    WarningIcon
  },
  props: ['token'],
  emits: ['logout', 'refresh'],
  setup() {
    const opened = ref(false)
    const interval = ref(null)
    const timeout = ref(null)

    return {
      interval,
      timeout,
      opened,
    }
  },
  mounted() {
    this.setChecker()
  },
  methods: {
    setChecker() {
      this.interval = setInterval(this.checkTokens, process.env.VUE_APP_SESSION_TIMEOUT)
    },
    checkTokens() {
      const token = getTokens().access

      if (token && getJWTExpire(token) - Date.now() < +process.env.VUE_APP_SESSION_TIMEOUT) {
        this.opened = true
        clearInterval(this.interval)
        this.timeout = setTimeout(this.logout, process.env.VUE_APP_SESSION_TIMEOUT)
      }
    },
    logout() {
      this.$emit('logout')
      clearTimeout(this.timeout)
    },
    refresh() {
      this.$emit('refresh')
      clearTimeout(this.timeout)
      this.opened = false
      this.setChecker()
    }
  }
}
</script>